import Link from 'components/atoms/link'
import Container from 'components/container'
import Layout from 'components/layout'
import Breadcrumbs from 'components/molecules/breadcrumbs'
import Card from 'components/molecules/card'
import Seo from 'components/molecules/seo'
import ArticleBody from 'components/organisms/articleBody'
import ArticleFooter from 'components/organisms/articleFooter'
import ArticleHeader from 'components/organisms/articleHeader'
import { renderDocumentToReactComponents } from 'components/organisms/renderDocumentToReactComponents'
import Share from 'components/organisms/share'
import TableOfContents from 'components/organisms/tableOfContents'
import { graphql, PageProps } from 'gatsby'
import { useResourceTextData } from 'hooks/useResourceTextData'
import * as React from 'react'
import { ContentfulArticle } from 'types/graphql-types'

type ArticleTemplateProps = PageProps & {
  data: {
    contentfulArticle: ContentfulArticle
    nextArticle: ContentfulArticle
    relatedArticles: {
      nodes: ContentfulArticle[]
    }
  }
  pageContext: {
    nextArticleSlug: string
  }
}

const ArticleTemplate = (props: ArticleTemplateProps): JSX.Element => {
  const { contentfulArticle, nextArticle, relatedArticles } = props.data
  const {
    title,
    heroImage,
    tags,
    leadingParagraph,
    showTableOfContents,
    body,
    category,
    metaDescription,
  } = contentfulArticle
  const hasImage = heroImage !== undefined

  return (
    <Layout>
      <Seo
        title={title}
        imageUrl={
          hasImage
            ? heroImage?.fluid?.src?.replace(/^\/\//, 'https://')
            : undefined
        }
        description={metaDescription?.metaDescription}
        url={props.location.href}
      />
      <ArticleHeader hasImage={hasImage}>
        <Container>
          <Breadcrumbs>
            <Link to="/community/">
              {useResourceTextData('community.title', 'Community')}
            </Link>{' '}
            <span>-</span>{' '}
            <Link to={`/community/category/${category.slug}/`}>
              {category.title}
            </Link>
          </Breadcrumbs>
          <Card
            size="lg"
            title={title}
            subTitle={{ title: category.title, slug: category.slug }}
            tags={tags}
            image={heroImage}
            hero
            share
          />
        </Container>
      </ArticleHeader>

      <ArticleBody hasImage={hasImage}>
        <Container>
          <div className="row">
            <div className="meta">
              {showTableOfContents && (
                <div className="desktop-toc">
                  <TableOfContents />
                </div>
              )}
              <Share />
            </div>
            <div className="content">
              {!hasImage && (
                <div className="tablet-title">
                  <h1 className="title">{title}</h1>
                </div>
              )}
              {leadingParagraph?.leadingParagraph && (
                <p className="lead">{leadingParagraph?.leadingParagraph}</p>
              )}
              {showTableOfContents && (
                <div className="tablet-toc">
                  <TableOfContents />
                </div>
              )}
              {body && renderDocumentToReactComponents(body)}
            </div>
          </div>
        </Container>
      </ArticleBody>

      <ArticleFooter>
        <Container narrow>
          {nextArticle && (
            <div className="next">
              <h3 className="heading">
                {useResourceTextData('community.nextArticle', 'Next article')}
              </h3>
              <Card
                size="lg"
                title={nextArticle.title}
                slug={`/community/${nextArticle.slug}/`}
                subTitle={{
                  title: nextArticle.category?.title,
                  slug: `/community/category/${nextArticle.category?.slug}/`,
                }}
                tags={nextArticle.tags}
                image={nextArticle.heroImage}
              />
            </div>
          )}

          <h3 className="heading">
            {useResourceTextData(
              'community.relatedArticles',
              'Related articles'
            )}
          </h3>
          <div className="cards">
            {relatedArticles.nodes.map(article => {
              return (
                <Card
                  title={article.title}
                  slug={`/community/${article.slug}/`}
                  subTitle={{
                    title: article.category.title,
                    slug: `/community/category/${article.category.slug}/`,
                  }}
                  image={article.heroImage}
                  key={article.id}
                />
              )
            })}
          </div>
        </Container>
      </ArticleFooter>
    </Layout>
  )
}

export const query = graphql`
  query ContentFulArticle(
    $slug: String
    $locale: String
    $categorySlug: String
    $nextArticleID: String
    $currentArticleID: String
    $nextArticleSlug: String
  ) {
    contentfulArticle(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      title
      slug
      node_locale
      heroImage {
        fluid {
          ...Image
        }
      }
      leadingParagraph {
        leadingParagraph
      }
      showTableOfContents
      body {
        raw
        references {
          ... on Node {
            ... on ContentfulImagesContentBlock {
              ...ContentfulImagesContentBlock
            }
            ... on ContentfulAsset {
              contentful_id
              title
              description
              fluid {
                ...Image
              }
            }
            ... on ContentfulVideo {
              contentful_id
              internal {
                type
              }
              url
            }
            ... on ContentfulLink {
              contentful_id
              internal {
                type
              }
              link
              title
            }
            ... on ContentfulPage {
              contentful_id
              title
              slug
              internal {
                type
              }
            }
            ... on ContentfulArticle {
              contentful_id
              title
              slug
              internal {
                type
              }
            }
            ... on ContentfulRegionalLink {
              contentful_id
              title
              nzSlug
              auSlug
              ukSlug
              sgSlug
              jpSlug
              internal {
                type
              }
            }
            ... on ContentfulAccordion {
              contentful_id
              title
              items {
                title
                body {
                  raw
                }
              }
              internal {
                type
              }
            }
            ... on ContentfulTable {
              contentful_id
              title
              hasHeader
              table {
                tableData
              }
              internal {
                type
              }
            }
            ... on ContentfulButton {
              contentful_id
              title
              regionallink: link {
                title
                nzSlug
                auSlug
                ukSlug
                sgSlug
                jpSlug
              }
              internal {
                type
              }
            }
            ... on ContentfulSecondaryButton {
              contentful_id
              title
              regionalLink: link {
                ukSlug
                auSlug
                nzSlug
                jpSlug
                sgSlug
              }
            }
          }
        }
      }
      category {
        id
        slug
        title
      }
      tags {
        title
        slug
        internal {
          type
        }
      }
      metaDescription {
        metaDescription
      }
    }
    nextArticle: contentfulArticle(
      slug: { eq: $nextArticleSlug }
      node_locale: { eq: $locale }
    ) {
      id
      title
      slug
      node_locale
      heroImage {
        fluid {
          ...Image
        }
      }
      category {
        id
        slug
        title
      }
      tags {
        title
        slug
        internal {
          type
        }
      }
    }
    relatedArticles: allContentfulArticle(
      limit: 3
      filter: {
        category: { slug: { eq: $categorySlug } }
        id: { nin: [$currentArticleID, $nextArticleID] }
        node_locale: { eq: $locale }
      }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        id
        title
        slug
        heroImage {
          fluid {
            ...Image
          }
        }
        category {
          id
          slug
          title
        }
      }
    }
  }
`

export default ArticleTemplate
